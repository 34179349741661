import _AnimatedNumber3 from "./AnimatedNumber";
var exports = {};
var _AnimatedNumber = _AnimatedNumber3;

var _AnimatedNumber2 = _interopRequireDefault(_AnimatedNumber);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports = _AnimatedNumber2.default;
export default exports;